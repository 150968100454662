<template>
  <h3>您已登出，请重新登录！</h3>
</template>
<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRouter()
    onMounted(() => {
      window.localStorage.removeItem('user_info')
      router.push('/auth/login')
    })
  },
})
</script>
